// WE NEED THIS FIREBASE ONLY FOR THIS ALERTS
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, where, query, Timestamp } from "firebase/firestore/lite";
import { ITradeNotification } from "./interfaces";
import getConfig from "next/config";
import { getAuth, onAuthStateChanged, signInAnonymously, signOut } from "firebase/auth";
import { filterByLatestTriggerTime, filterOutHidden } from "./helpers";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const { publicRuntimeConfig } = getConfig();
const firebaseConfig = publicRuntimeConfig["firebaseConfig"];
// const firebaseConfig = {
//     apiKey: "AIzaSyALKIgti7dwYpbspAMt3BtVplEEAsFirqQ",
//     authDomain: "preproduction-4b513.firebaseapp.com",
//     databaseURL: "https://preproduction-4b513.firebaseio.com",
//     projectId: "preproduction-4b513",
//     storageBucket: "preproduction-4b513.appspot.com",
//     messagingSenderId: "465430736163",
//     appId: "1:465430736163:web:cc6e34b33e39f6086bb096",
//     measurementId: "G-LESLBHFE04",
// };

// Initialize Firebase
let app = null;
if (!app) {
    app = initializeApp(firebaseConfig);
}

const db = getFirestore(app);

const auth = getAuth(app);
//signOut(auth);
onAuthStateChanged(auth, async user => {
    if (!user) await signInAnonymously(auth);
});
export const fetchAlertsDataFromFirebase = async (symbolsInfoById): Promise<ITradeNotification[]> => {
    try {
        const alerts = collection(db, "trade.notifications");

        const cutoffDate = new Date();
        cutoffDate.setHours(cutoffDate.getHours() - 48);

        const q = query(alerts, where("creationTime", ">", Timestamp.fromDate(cutoffDate)));

        const alertsSnapshot = await getDocs(q);

        const result: ITradeNotification[] = alertsSnapshot.docs.map(doc => {
            const data = doc.data();
            const milliseconds =
                data.creationTime.seconds * 1000 + data.creationTime.nanoseconds / 1000000;
            const _date = new Date(milliseconds);

            const ifRetail = symbolsInfoById[`${data.assetName}!`];

            return {
                id: doc.id,
                assetName: ifRetail ? `${data.assetName}!` : data.assetName,
                displayName: data.displayName,
                category: data.category,
                basePrice: data.basePrice,
                baseTime: data.baseTime,
                triggerPrice: data.triggerPrice,
                triggerTime: data.triggerTime,
                triggerDeviation: data.triggerDeviation,
                configuredDeviation: data.configuredDeviation,
                creationTime: data.creationTime,
                triggerTimeDiv: data.triggerTimeDiv,
                digits: data.digits,
                text: data.text,
                date: _date,
            } as ITradeNotification;
        });

        return filterByLatestTriggerTime(filterOutHidden(result, symbolsInfoById));
    } catch (error) {
        throw new Error(error);
    }
};
