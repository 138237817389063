import React from "react";
import clsx from "clsx";
import { ITradeOrder } from "@/services/trade/order";
import { useTradeInfo } from "@/redux/selectors/tradeInfoSelector";

import styles from "./TradeHeader.module.scss";


interface IProps {
    tradeData: ITradeOrder;
}

const TradeHeader = ({ tradeData }: IProps): JSX.Element => {
    const isBuy = tradeData.type.toLowerCase().includes("buy") ||
        tradeData.type.toLowerCase().includes("balance");
    const { symbolsInfoById } = useTradeInfo();
    const symbolInfo = symbolsInfoById[tradeData.symbol];

    return (
        <div className={styles.container}>
            <div className={clsx(styles.directionIconContainer,{
                [styles.buy]: isBuy,
                [styles.sell]: !isBuy,
            })}>
                <div className={clsx(styles.directionIcon)}/>
            </div>

            <div className={styles.headerMainInfo}>
                <div className={styles.headerMainInfo__symbol}>
                    {symbolInfo?.ex?.displayName || tradeData.symbol}
                </div>
                <div className={styles.headerMainInfo__direction}>
                    {tradeData.ticket && `ID #${tradeData.ticket}`}
                </div>
            </div>
        </div>
    );
};

export default React.memo(TradeHeader);
